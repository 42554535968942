<template>
    <ul class="step_nav flex-center">
        <li class="flex-center flex-column-center" :class="{'active': stepIndex > 0}" >
            <span class="num">1</span>
            <span class="title">{{ $sys("account.loginAccount") }}</span>

        </li>
        <li class="flex-center flex-column-center" :class="{'active': stepIndex > 1}" >
            <span class="num">2</span>
            <span class="title">{{ $sys("account.selectRole") }}</span>
            <span class="tail"></span>
        </li>
        <li class="flex-center flex-column-center" :class="{'active': stepIndex > 2}" >
            <span class="num">3</span>
            <span class="title">{{ $sys("account.startReachrge") }}</span>
            <span class="tail"></span>
        </li>
    </ul>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'StepNav',
    computed: {
        ...mapState('game', ['stepName']),
        stepIndex() {
            return ['login', 'role', 'mall'].indexOf(this.stepName) + 1
        }
    }
}
</script>

<style lang="scss" scoped>

</style>