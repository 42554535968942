<template>
  <Popup
    v-model="visible"
    :title="$sys('mall.title.payCart')"
    :width="isMobile ? '100%' : '1000px'"
    :position="isMobile ? 'bottom' : 'center'"
    class="pay_cart_pop"
  >

    <div class="pop_pay_cart">
      <RoleInfo />

      <GoodsInfo />

      <PayTypes />

      <div class="bottom">
        <div class="total">
          <p>
            {{ $sys('mall.totalPrize') }}
            <span class="prize">{{ prizePay }}</span>
          </p>
        </div>
        <el-button
          :type="isCanPay ? 'primary' : 'info'"
          @click="() => handlePay()"
          :loading="isPaying"
          :disabled="!isCanPay"
          class="btn">
          {{ $sys('btn.payNow') }}
        </el-button>
      </div>
    </div>

  </Popup>
</template>

<script>
import Popup from '@/components/Popup/M';
import { formatCurrencyToThousand, tips } from '@/utils';
import { aliGlobalPay, canOpenWindow, linkJumpPay } from '@/utils/pay';
import { Button } from 'element-ui';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import GoodsInfo from './GoodsInfo';
import PayTypes from './PayTypes';
import RoleInfo from './RoleInfo';

export default {
  components: {
    [Button.name]: Button,
    Popup,
    RoleInfo,
    GoodsInfo,
    PayTypes
  },
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isPaying: false
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isSelectGoods', 'isCanPay']),
    ...mapState('mall', ['goodsInfo', 'payInfo']),
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    prizePay() {
      if (this.isSelectGoods) {
        const { commodityCurrency, prizeTotal } = this.goodsInfo
        return commodityCurrency + formatCurrencyToThousand(prizeTotal)
      }else {
        return ''
      }
    },
    status() {
      return [this.payInfo, this.goodsInfo]
    }
  },
  methods: {
    ...mapMutations('mall', ['SET_GOODS_INFO']),
    ...mapMutations('user', ['SET_USER_INFO']),
    ...mapActions('mall', ['createOrder']),
    async handlePay() {
      if (!this.payInfo?.payType) {
        tips(this.$sys('warn.pleaseSelectPayMethod'))
        return
      }

      this.isPaying = true
      canOpenWindow(this.payInfo.paySubType || this.payInfo.payType, async (newWindow) => {
        try {
          const result = await this.createOrder()

          this.isPaying = false

          const { appLinkUrl, normalUrl, schemeUrl, orderCode, payPage, paymentSessionData, waitSecond } = result

          if (paymentSessionData) {
            aliGlobalPay(paymentSessionData, !this.payInfo.cardId)
          } else {
            if (this.payInfo?.payType === 'MYCARD') {
              if (payPage) {
                linkJumpPay(newWindow, payPage)
              }
            } else {
              if(this.isMobile) {
                linkJumpPay(newWindow, appLinkUrl || normalUrl)
              } else {
                linkJumpPay(newWindow, normalUrl)
              }
            }
          }

          this.SET_USER_INFO({ payRecentlyUse: this.payInfo })

          this.$emit('onPay', {
            time: waitSecond,
            order: orderCode,
            isPop: !!payPage
          })
        } catch (e) {
          this.isPaying = false
          newWindow && newWindow.close()
        }
      })
    },
    reset(){
      this.isPaying = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
