<template>
    <div class="cart_goods_info">
        <h3 class="title">{{ $sys('mall.title.buy') }}</h3>
        <div class="content">
            <div class="flex-between">
                <div class="flex-start">
                    <div class="product_img">
                        <img :src="goodsInfo.commodityIcon" alt="" />
                    </div>
                    <div>
                        <p class="product_name">{{ goodsInfo.commodityName }}</p>
                        <p class="product_price">{{ goodsInfo.commodityCurrency + toCurrencyToThousand(goodsInfo.commoditySellPrice) }}</p>
                    </div>
                </div>
                
                <div class="buy_number">
                    <el-input-number v-model="number" :min="1" :max="99" :precision="0" @change="handleNumber"></el-input-number>
                </div>
            </div>
            <ul class="list">
                <li class="flex-between">
                    <span class="label">{{ $sys('mall.goodsPrizeOriginal') }}</span>
                    <span class="value">{{ goodsInfo.commodityCurrency + toCurrencyToThousand(goodsInfo.commodityOriginalPrice) }}</span>
                </li>
                <li class="flex-between">
                    <span class="label">{{ $sys('mall.goodsAdd') }}</span>
                    <span class="value">{{ goodsInfo.preferentialExplain || '-' }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { InputNumber } from 'element-ui'
import { logAddNumber, logReduceNumber } from '@/utils/log'
import { formatCurrencyToThousand } from '@/utils'

export default {
    components: {
        [InputNumber.name]: InputNumber
    },
    computed: {
        ...mapState('user', ['userInfo']),
        ...mapState('mall', ['goodsInfo']),
        number: {
            get() {
                return this.goodsInfo.commodityNum
            },
            set(num) {
                if(num != this.goodsInfo.commodityNum) {
                    this.countGoods(num)
                }
            }
        }
    },
    methods: {
        ...mapActions('mall', ['countGoods']),
        toCurrencyToThousand(value){
            return formatCurrencyToThousand(value)
        },
        handleNumber(currentValue, oldValue) {
            if(currentValue > oldValue) {
                logAddNumber(this.goodsInfo.commodityCode)
            }else {
                logReduceNumber(this.goodsInfo.commodityCode)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>