<template>
  <div class='goods_section'>

    <Title :name="$sys('mall.goodsTitle')"></Title>

    <DynamicCopywriting />

    <ul class='goods_list flex-start' v-if='goodsList.length > 0' v-loading="loading">
      <li v-for='(item, index) in goodsList' :key='index'>
        <a
          href='javascript:;'
          class='item p-r flex-column-center clickable'
          :class="{'active': goodsInfo.commodityCode == item.commodityCode}"
          @click='handleChoose(item)'
        >
          <span v-if='item.cornerMarkText' class='corner-mark'>{{ item.cornerMarkText }}</span>

          <span v-if='item.isRecommend' class='hot'></span>

          <img :src='item.commodityIcon' class='img' alt=''>
          
          <div class='goods-wrap'>

            <p class='name ellipse' :title='item.commodityName'>{{ item.commodityName }}</p>

            <p class='descr'>{{ item.preferentialExplain }}</p>

            <p class='prize'>
              <template v-if='item.currencyFrontLocation == 11'>
                <a>{{ toCurrencyThousand(item.commoditySellPrice) }} {{ item.commodityCurrency }} </a>
              </template>
              <template v-else>
                {{ item.commodityCurrency }} {{ toCurrencyThousand(item.commoditySellPrice) }}
              </template>
            </p>

          </div>
        </a>
      </li>
    </ul>
    
    <div :class='["instructions"]' v-html="$ps('recharge.instructions')"></div>

  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Title from '../Title'
import DynamicCopywriting from './DynamicCopywriting.vue'
import { formatDateTime, formatCurrencyToThousand } from '@/utils'

export default {
  components: {
    Title,
    DynamicCopywriting
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters(['isMaintain', 'country', 'lang', 'isLogin']),
    ...mapState('user', ['userInfo']),
    ...mapState('game', ['gameInfo']),
    ...mapState('mall', ['goodsList', 'goodsInfo']),
    status() {
      return [this.country, this.lang, this.userInfo]
    }
  },
  methods: {
    formatDateTime,
    ...mapActions('user', ['login']),
    ...mapActions('mall', ['getGoodsList', 'selectGoods']),
    handleChoose(info) {
      if(!this.isLogin) {
        this.login(info.commodityCode)
        return
      }

      this.$emit('onSelect')
      this.selectGoods(info)
    },
    toCurrencyThousand(value){
      return formatCurrencyToThousand(value)
    }
  },
  watch: {
    status: {
      async handler() {
        this.loading = true
        this.selectGoods()
        if(!this.isMaintain) {
          await this.getGoodsList()
        }
        this.loading = false
      },
      immediate: true
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
<style lang='scss'>
.instructions {
  margin-top: 100px;
  line-height: 30px;
  box-sizing: border-box;
  padding: 10px 0;
  font-size: 14px;
  color: #999999;
  font-weight: normal;
  p {
    min-height: 20px;
  }

  p:first-child {
    font-size: 16px;
    color: #333333;
  }

  p:nth-child(6) {
    font-size: 16px;
    color: #333333;
  }
  

}

@media only screen and (max-width: 750px) {
  .instructions {
    font-size: 14px;
    line-height: 31px;
    margin-top: 50px;

    p:first-child {
      font-size: 16px;
      margin-bottom: 5px
    }

    p:nth-child(6) {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}

</style>