<template>
    <div class="page_game">
        <Banner></Banner>

        <div v-if="!isMaintain">
            <div class="page_content box" v-if="stepName === 'login'">
                <StepNav />
                <StepLogin />
            </div>

            <div class="page_content box" v-if="stepName === 'role'">
                <StepNav />
                <StepRole />
            </div>

            <div class="page_content" v-if="stepName === 'mall'">
                <StepPay />
            </div>
        </div>

        <div v-else class="page_content">
            <RoleInfo v-if="!isLogin"/>

            <div class="text-center maintain_box">
                {{ $sys('warn.maintaining', {  
                    date: dateShow
                }) }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatTimeZoneDateTime } from '@/utils'
import Banner from "@/components/Banner"
import StepNav from './components/StepNav'
import StepLogin from './components/StepLogin'
import StepRole from './components/StepRole'
import StepPay from './components/StepPay'
import RoleInfo from './components/RoleInfo'

export default {
    name: 'XyPay',
    components: {
        StepNav,
        StepLogin,
        StepRole,
        StepPay,
        Banner,
        RoleInfo
    },
    computed: {
        ...mapState('game', ['stepName', 'gameInfo']),
        ...mapGetters(['isMaintain', 'isLogin']),
        dateShow() {
            const { timeZoneName, timeZoneGMT } = this.gameInfo
            return `${timeZoneGMT} ${this.formatTimeZoneDateTime(timeZoneName, this.gameInfo.maintainEndTime)}`
        }
    },
    methods: {
        formatTimeZoneDateTime
    }
}
</script>

<style lang="scss" scoped>
.maintain_box {
    background-color: #fff;
    padding: 200px 0 !important;
}
</style>
