<template>
    <div class="step_pay">
        <RoleInfo />

        <GoodsList @onSelect="dialogCart = true" />

        <Popup 
            v-model="dialogTips" 
            :title="popInfo.title" 
            :msg="popInfo.msg" 
            :btn="popInfo.btn"
            :msgAlign="popInfo.align" 
            :tips="popInfo.tips" 
            @init="popInfo.init" 
            @close="handleCloseWait"
            :modelClose="false" 
            @confirm="handleCloseWait" />

        <PayCart ref="payCart" v-model="dialogCart" @close="dialogCart = false" @onPay="info => popupWait(info)" />

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatTime } from '@/utils'
import { getPayResult, removePayResult, getPayInfo, removePayInfo } from '@/utils/storage'
import { apiOrderResult } from '@/api/mall'
import { logPurchaseSuccess, logPurchaseFail } from '@/utils/log'
import Popup from '@/components/Popup'
import GoodsList from './GoodsList'
import RoleInfo from '../RoleInfo'
import PayCart from '../PayCart'

const popInfoDefault = {
    title: '',
    msg: '',
    btn: null,
    tips: null,
    init: () => { }
}

export default {
    name: 'StepPay',
    components: {
        RoleInfo,
        GoodsList,
        Popup,
        PayCart
    },
    data() {
        return {
            dialogTips: false,
            dialogCart: false,
            popInfo: Object.assign({}, popInfoDefault),
            timerTime: undefined,
            remainingTime: 0,
            payInfo: null
        }
    },
    computed: {
        ...mapGetters(['isSelectGoods'])
    },
    methods: {
        ...mapActions('mall', ['getGoodsList', 'selectGoods']),
        popupWait({ time, order, isPop }) {
            this.dialogCart = false

            if (isPop) {
                this.popup({
                    btn: this.$sys('btn.confirm'),
                    align: 'center',
                    tips: this.$sys('warn.paying'),
                    init: () => {
                        this.timerTime = setInterval(() => {
                            const payResult = getPayResult()
                            if (payResult == 0) {
                                this.handleCloseWait()
                            }
                            if (payResult == 1) {
                                this.handleCloseWait()
                                setTimeout(() => {
                                    this.popup({
                                        title: this.$sys('mall.buySuccess'),
                                        msg: this.$sys('mall.buySuccessMsg'),
                                        btn: this.$sys('mall.buyContinue')
                                    })
                                }, 1000)
                            }
                            if (time % 20 === 0) {
                                time = this.remainingTime
                            }
                            if (time >= 0) {
                                this.popInfo.msg = `${this.$sys('warn.payWaiting')}<br/><span style="color:red">${formatTime(time)}</span>`
                                time--
                            } else {
                                this.handleCloseWait()
                            }
                        }, 1000)
                    }
                })

                this.queryPayResult(order)
            }


            this.getGoodsList()
        },
        handleCloseWait() {
            removePayResult()
            this.$refs.payCart && this.$refs.payCart.reset()
            this.dialogTips = false
            this.timerTime && clearInterval(this.timerTime)
            this.selectGoods()

            this.getGoodsList()     // 后加，为了刷新商品角标
        },
        async queryPayResult(orderCode) {

            const result = await apiOrderResult(orderCode)

            if (result.code === 0) {
                this.remainingTime = result.data.timeRemain

                this.payInfo = this.payInfo || getPayInfo()

                if (result.data.paySuccess) {
                    this.handleCloseWait()
                    this.popup({
                        title: this.$sys('mall.buySuccess'),
                        msg: this.$sys('mall.buySuccessMsg'),
                        btn: this.$sys('mall.buyContinue')
                    })

                    if(this.payInfo) {
                        logPurchaseSuccess(this.payInfo)
                        removePayInfo()
                    }
                } else if (result.data.paySuccess === false){
                    this.handleCloseWait()

                    this.popup({
                        msg: this.$sys('mall.status.payFail'),
                        btn: this.$sys('btn.confirm')
                    })

                    if(this.payInfo) {
                        logPurchaseFail('other')
                        removePayInfo()
                    }
                } else {
                    if (this.dialogTips) {
                        setTimeout(() => {
                            this.queryPayResult(orderCode)
                        }, 1000)
                    }
                }
            }
        },
        popup(info) {
            this.dialogTips = false
            this.popInfo = Object.assign({}, popInfoDefault, info)
            this.dialogTips = true
        }
    },
    watch: {
        isSelectGoods(val) {
            if (val) this.dialogCart = true
        }
    }
}
</script>

<style lang="scss" scoped>
.step_pay {
    background-color: #fff;
}
</style>