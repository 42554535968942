<template>
    <div class="pay_types">
        <h3 class="title">{{ $sys('mall.title.payType') }}</h3>

        <el-tree 
            v-if="payList && payList.length"
            ref="tree"
            class="pay_types_tree" 
            :data="dataList" 
            :props="defaultProps" 
            node-key="id"
            highlight-current 
            default-expand-all 
            :default-checked-keys="[this.payInfo.id]"
            @node-click="handleClick"
        ></el-tree>

        <div v-else class="no_pay_method">{{ $sys('warn.payAmountExceedLimit') }}</div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { Tree } from 'element-ui'
import { getPayIconByName } from '@/utils/pay'
import { logChooseMethod } from '@/utils/log'

export default {
    components: {
        [Tree.name]: Tree
    },
    data() {
        return {
            defaultProps: {
                children: 'children',
                label: (info) => {

                    const renderRecommend = () => {
                        return <span class='sign_recommend'>{this.$sys("mall.recommend")}</span>
                    }

                    if(info.children) {
                        return info.label
                    }else {
                        if(info.cardId) {
                            const imgUrl = getPayIconByName(info.cardBrand)
                            return (
                                <div>
                                    <span class='icon_radio'>
                                        <i class='el-icon-check' />
                                    </span>
                                    <img src={imgUrl} height={20} style={{marginRight: '10px'}}/>
                                    <span>{info.label}</span>

                                    {info.id === this.payRecentlyUse?.id && renderRecommend()}
                                </div>
                            )
                        }else {
                            const renderPayIcon = () => {
                                if( info.payType === 'CARD') {
                                    return (
                                        <div style={{float: 'right'}} >
                                            <img class='icon_pay_type' src={getPayIconByName('visa')} height={20} style={{marginRight: '10px'}}/>
                                            <img class='icon_pay_type' src={getPayIconByName('mastercard')} height={20} style={{marginRight: '10px'}}/>
                                        </div>
                                    )
                                }else {
                                    const imgUrl = getPayIconByName(info.payType) || getPayIconByName(info.payMethod)
                                    return <img class='icon_pay_type' src={imgUrl} height={20} style={{marginRight: '10px'}}/>
                                }
                            }

                            return (
                                <div>
                                    {renderPayIcon()}
                                    
                                    <span class='icon_radio'>
                                        <i class='el-icon-check' />
                                    </span>
                                    <span>{info.label}</span>

                                    {info.id === this.payRecentlyUse?.id && renderRecommend()}
                                </div>
                            )
                        }
                    }
                }
            }
        }
    },
    computed: {
        ...mapState('mall', ['payList', 'payInfo']),
        ...mapGetters(['payRecentlyUse']),
        dataList() {
            const mycardInfo = this.payList.find(item => item.payType === 'MYCARD')
            const aliPayInfo = this.payList.find(item => item.payType === 'ALIPAY_GLOBAL')
            
            const paySubTypes = aliPayInfo?.paySubTypes?.map(item => ({
                label: item.payTypeName,
                payMethod: aliPayInfo?.payType,
                payType: item.payType,
                category: item.category,
                cards: item.cards,
                id: item.payType
            })) || []

            const cardInfo = paySubTypes?.find(item => item.payType === 'CARD')
            const cardList = cardInfo?.cards?.map((item) => ({
                cardId: item.id,
                label: item.cardNo,
                cardBrand: item.cardBrand,
                payMethod: cardInfo.payMethod,
                payType: cardInfo.payType,
                id: `${item.cardBrand}-${item.id}`
            })) || []

            const otherTypes = paySubTypes?.filter(item => {
                return !['BANKTRANSFER', 'BANKAPP', 'ONLINEBANKING'].includes(item.category) && item.payType != 'CARD'
            }) || []

            let listData = [
                {
                    id: 'aliCard',
                    label: this.$sys('mall.type.card'),
                    children: [
                        ...cardList,
                        {
                            ...cardInfo,
                            label: this.$sys('mall.otherCard')
                        }
                    ]
                },
                {
                    id: 'bankApp',
                    label: this.$sys('mall.type.bankApp'),
                    children: paySubTypes?.filter(item => item.category === 'BANKAPP')
                },
                {
                    id: 'onlineBank',
                    label: this.$sys('mall.type.onlineBank'),
                    children: paySubTypes?.filter(item => item.category === 'ONLINEBANKING')
                },
              {
                id: 'bankTransfer',
                label: this.$sys('mall.type.bankTransfer'),
                children: paySubTypes?.filter(item => item.category === 'BANKTRANSFER')
              },
                ...otherTypes,
              {
                id: 'mycard',
                label: mycardInfo?.payTypeName,
                payMethod: mycardInfo?.payType
              }
            ]

            listData = listData.filter(item => {
                if(item.id === 'aliCard') {
                    return !!cardInfo
                }else if(item.id === 'mycard') {
                    return !!mycardInfo
                }else if(['bankApp', 'bankTransfer', 'onlineBank'].includes(item.id)) {
                    return item.children.length
                }else {
                    return true
                }
            })

            // 初始化支付方式
            let isHasChooise = undefined        // 列表是否有已选择方式
            listData.map(item => {
                if(item.id === this.payInfo?.id) {
                    isHasChooise = true
                }
                item.children?.map(sub => {
                    if(sub.id === this.payInfo?.id) {
                        isHasChooise = true
                    }
                })
            })
            if(!isHasChooise) this.SET_PAY_INFO({})

            return listData
        }
    },
    methods: {
        ...mapMutations('mall', ['SET_PAY_INFO']),
        handleClick(info) {

            if(info.children) return

            this.$refs.tree.setCheckedKeys([info.id])

            if(info.payType) {
                this.SET_PAY_INFO({
                    payType: info.payMethod?.toLocaleUpperCase(),
                    paySubType: info.payType?.toLocaleUpperCase(),
                    cardId: info.cardId,
                    id: info.id
                })

                logChooseMethod(info.payType?.toLocaleUpperCase())
            }else {
                this.SET_PAY_INFO({
                    payType: info.payMethod?.toLocaleUpperCase(),
                    id: info.id
                })

                logChooseMethod(info.payMethod?.toLocaleUpperCase())
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>