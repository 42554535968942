<template>
    <div class="step_login flex-column-center">

        <div class="flex-column-center">

            <div class="game_icon_box flex-center">
                <img :src="gameIcon" alt="game-icon" class="game_icon">
            </div>

            <div class="game_name">{{ $ps('game.name') }}</div>

            <a href="javascript:;" class="btn_login flex-center" @click="handleLogin">{{ $sys('btn.login') }}</a>

            <p class="tips">{{ $sys('warn.loginTips') }}</p>

        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getGameIconByUrl, getGameNameByUrl } from '@/utils/game';

export default {
    name: 'StepLogin',
    data() {
        return {
            gameIcon: getGameIconByUrl(),
            gameName: getGameNameByUrl()
        }
    },
    methods: {
        ...mapActions('user', ['login']),
        handleLogin() {
            this.login()
        }
    }
}
</script>

<style lang="scss" scoped></style>