var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page_game" },
    [
      _c("Banner"),
      !_vm.isMaintain
        ? _c("div", [
            _vm.stepName === "login"
              ? _c(
                  "div",
                  { staticClass: "page_content box" },
                  [_c("StepNav"), _c("StepLogin")],
                  1
                )
              : _vm._e(),
            _vm.stepName === "role"
              ? _c(
                  "div",
                  { staticClass: "page_content box" },
                  [_c("StepNav"), _c("StepRole")],
                  1
                )
              : _vm._e(),
            _vm.stepName === "mall"
              ? _c("div", { staticClass: "page_content" }, [_c("StepPay")], 1)
              : _vm._e(),
          ])
        : _c(
            "div",
            { staticClass: "page_content" },
            [
              !_vm.isLogin ? _c("RoleInfo") : _vm._e(),
              _c("div", { staticClass: "text-center maintain_box" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$sys("warn.maintaining", { date: _vm.dateShow })
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }